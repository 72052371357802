/** @jsx jsx */
// import React from 'react'

import Jumbotron from 'react-bootstrap/Jumbotron'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import styled from '@emotion/styled'
// import InfoCard from '../components/InfoCard'
import Layout from '../components/Layout'
import VideoArchive from '../components/VideoArchive'

import { Flex, Box, jsx } from 'theme-ui'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons/faGithub'
import { faVideo, faHome, faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import Image from 'react-bootstrap/Image'

import Helmet from 'react-helmet'

import 'src/css/theme.scss'
import favicon from '../assets/favicon.ico'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { config } from '@fortawesome/fontawesome-svg-core'

// import { AppsContext } from '../../../AppsProvider'
// // Prevent fontawesome from dynamically adding its css since we did it manually above
config.autoAddCss = false

const MastHead = styled(Jumbotron)`
  background-color: ${props => props.theme.colors.dark};
  padding: 0;
  color: white;
  padding-bottom: 0.5rem;

  position: relative;
 
  height: 100%;
  background-size: cover;
`
const homepageBgColor = `${props => props.theme.colors.homepageBgColor}`
 // background: #000 url("${require(`../assets/videoclips/lavaflow-cover.mp4.gif`)}") center center;  width: 100%;

// position: relative;
//   background: #000 url("https://s3.amazonaws.com/assets.shotloadr.com/anneshort.mov.gif") center center;
//   width: 100%;
//   height: 100%;
//   background-size: cover;

const Content = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 400px;
  margin: 0 auto;
  margin-right: 100px;
  max-width: 800;
  text-align: right;
  @media (max-width: 800) {
    padding: 0 0px;
    text-align: right;
  } 
`

const CoverLogoDiv = styled('div')`
  opacity: 0.6;
`

const Title = styled('p')`
  font-size: 1.5rem;
  margin: 2rem 0;
`

/* Style the video: 100% width and height to cover the entire window */
// #myVideo {
//   position: fixed;
//   right: 0;
//   bottom: 0;
//   min-width: 100%;
//   min-height: 100%;
// }

/* Add some content at the bottom of the video/page */
// .content {
//   position: fixed;
//   bottom: 0;
//   background: rgba(0, 0, 0, 0.5);
//   color: #f1f1f1;
//   width: 100%;
//   padding: 20px;
// }

/* Style the button used to pause/play the video */
// #myBtn {
//   width: 200px;
//   font-size: 18px;
//   padding: 10px;
//   border: none;
//   background: #000;
//   color: #fff;
//   cursor: pointer;
// }

// #myBtn:hover {
//   background: #ddd;
//   color: black;
// }

const IndexPage = () => {

  // const appsContext = React.useContext(AppsContext)

  const { allProject, setting } = useStaticQuery(graphql`
    query {
      allProject {
        nodes {
          name
          url
          image
          description
        }
      }
      setting {
        url
        title
        organization
        logoName
      }
    }
  `)

  const projects = allProject.nodes




  return (
    <Layout>
      <Helmet>
        <title>Shotwire Video</title>
        <link rel="icon" href={favicon} type="image/x-icon" />
  
      </Helmet>
 { 
     // <MastHead fluid>
     //       <Content>
          
     //       <CoverLogoDiv>
           
 
     //        // The video
     //           // <video autoplay muted loop id="myVideo">
     //           //   <source src="rain.mp4" type="video/mp4">
     //           // </video>
 
     //           // // Optional: some overlay text to describe the video 
     //           // <div class="content">
     //           //   <h1>Heading</h1>
     //           //   <p>Lorem ipsum...</p>
     //           //   // Use a button to pause/play the video with JavaScript 
     //           //   <button id="myBtn" onclick="myFunction()">Pause</button>
     //           // </div>
           
     //       // <Image
     //       //   src={require(`../assets/shotwire-logo.png`)}
     //       //   alt="shotwire"
     //       //   height={168}
     //       //   width={168}
     //       //   rounded
     //       // />
          
     //       </CoverLogoDiv>
 
 
     //       <div sx={{height:'700px'}}>
 
     //         // <Button variant="dark" href={setting.url} size="lg">
     //         //   <FontAwesomeIcon
     //         //     icon={faVideo}
     //         //     size="lg"
     //         //     sx={{
     //         //       marginRight: '0.5rem'
     //         //     }}
     //         //   />{' '}
     //         //   View More
     //         // </Button>
   
     //       </div>
     //     </Content>
     //   </MastHead>
  }
  <VideoArchive />
  <Container>
      
      
       { 
               // <Flex
               //   sx={{
               //     flexWrap: 'wrap'
               //   }}
               // >
               //   {projects.map((project, key) => (
               //     <Box sx={{ width: ['100%', '50%', '33%'], px: 2, py: 2 }} key={key}>
               //       <InfoCard name={project.name} href={project.url} image={project.image}>
               //         {project.description}
               //       </InfoCard>
               //     </Box>
               //   ))}
               // </Flex>
               }
      </Container>
     

    </Layout>
  )
}

export default IndexPage
