/** @jsx jsx */
import React, { useReducer, useContext, useEffect } from 'react'

import Amplify from "@aws-amplify/core";
import { API, graphqlOperation, Cache } from "aws-amplify"
import { ListAllAssets, ListShotAssetsDeltasIndex, ListShotAssetsDateDeltasIndex, listShotwireAssetsDeltasPublic, listAssetsByCategoryPublic  } from "../../../graphql/shotassetQueries"
import { listShotwireAssetsDeltas, listAssetsByCategory } from "../../../graphql/queries"

import { Container, Flex, Box, jsx } from 'theme-ui'
import { Link } from 'gatsby'
// import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import Spinner from 'react-bootstrap/Spinner'
import VideoCard from './VideoCard'
// import Layout from '../@geocine/gatsby-theme-organization/components/Layout'

import Helmet from 'react-helmet'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faAngleLeft, faSpinner} from "@fortawesome/free-solid-svg-icons";

// import Layout from "../components/layout"
// import SEO from "../components/seo"
import awsconfig from "../../../aws-exports";

import 'src/css/theme.scss'
import favicon from '../assets/favicon.ico'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { config } from '@fortawesome/fontawesome-svg-core'

import { AppsContext } from '../../../AppsProvider'


// Prevent fontawesome from dynamically adding its css since we did it manually above
config.autoAddCss = false



// http://d1w2buicv2uw3m.cloudfront.net/V2/6B3E1A00BDAB94E4DA166A30A759D44C/assets/9519550.jpg
Amplify.configure(awsconfig); // Configure Amplify

const colorWhite50 = "rgba(255,255,255, .5)"
const colorBlack50 = "rgba(0,0,0, .5)"
const colorBlack80 = "rgba(0,0,0, .8)"
const colorWhiteHover = "rgba(255,255,255, .7)"
const colorSubLinkBackground = "rgba(0,0,0, .1)"
const homepageBgColor = "rgb(35, 47, 62)"

const linkStyle = {
  color: 'black',
	float: 'left',
	padding: '8px 16px',
	textDecoration: 'none',
	transition: 'backgroundColor .3s',
	':hover': '',
}
    
    // console.log('props:',props.theme.colors.background)
const whiteBg = '#FFF'//`${props => props.theme.colors.background}` 
const bodyText = 'rgba(255,255,255, .9)'

const VideoArchiveView = () => {
  
  let userInfo = null, 
      uRoles = null, 
      uGroups = null, 
      metaForm = {}, 
      searchParams,
      cart = [],
      favs = []

  const appsContext = React.useContext(AppsContext)

  const currentCategory = "RedCarpetEvents"
	const pageNumbers = [1,2,3,4,5,6,7,8,9,10]

	const pages = [1,2,3,4,5,6,7,8];
  let activePage = 1;
  	

  const setCurrentPage = (num) => {
      appsContext.state.currentPage = num
      appsContext.state.activePage = num
      appsContext.state.start = (appsContext.state.activePage-1)*appsContext.state.limit+1

    }
  const handlePageClick = (e, index) => {
     e.preventDefault();
     setCurrentPage(index);
     activePage =  index 
     appsContext.dispatch({ type: "PAGER", activePage });

  };

  const handleNextPageClick = (e, index) => {
     e.preventDefault();
     if(index <= 15) setCurrentPage(index+1);
     activePage =  index + 1


     appsContext.dispatch({ type: "PAGER", activePage });
     appsContext.dispatch({ type: "PAGETOKENS", nextToken: appsContext.state.nextToken })
  };

  const handlePrevPageClick = (e, index) => {
     e.preventDefault();
     if(index > 1) setCurrentPage(index-1)     
     activePage =  index - 1
     appsContext.dispatch({ type: "PAGER", activePage });
     if(index==0) appsContext.dispatch({ type: "PAGETOKENS", nextToken: null })
     else appsContext.dispatch({ type: "PAGETOKENS", nextToken: appsContext.state.prevToken })
  };

useEffect(() => {
      // console.log('get for user product and stats...')
      let isMounted = true
      if(isMounted === true){
        // console.log('setting up user...')

          if(appsContext.state.user){
            const  userInfo  = appsContext.state.user
            // console.log('userInfo: ', userInfo)

            if(typeof userInfo.signInUserSession.idToken.payload[`cognito:groups`] != 'undefined'){
              uGroups = userInfo.signInUserSession.idToken.payload[`cognito:groups`]
              // console.log('uGroups:',uGroups)
              // console.log('uRoles: ', uRoles)
              if( uGroups.some(r => ['Admins'].indexOf(r) >= 0) ){
                // console.log('Admin user.')
                 appsContext.dispatch({ type: "ISADMIN", isAdmin:true }) 
               }
              if( uGroups.some(r => ['Uploaders'].indexOf(r) >= 0) ){
                // console.log('Uploader user.')
                 appsContext.dispatch({ type: "ISUPLOADER", isUploader:true }) 
               }
              // console.log('isAdmin:', isAdmin)
            }

         
          }else{
            appsContext.dispatch({ type: "ISADMIN", isAdmin:false }) 
            appsContext.dispatch({ type: "ISUPLOADER", isUploader:false }) 
          }
        }

        return () => {
                  //console.log('state2: ', state)
                  // subscription.unsubscribe();
                  isMounted = false
                  
                };

  },[])

	useEffect(() => {
        let isMounted = true
        try{
          if(isMounted === true){
            // console.log('userInfo: ', userInfo)
            cart = Cache.getItem('shotwire-shopCart')
            appsContext.dispatch({type:"SHOPCART", shopCart:cart}) 
            favs = Cache.getItem('shotwire-favorites')
            appsContext.dispatch({type:"FAVORITES", favorites:favs})
          
            // if(appsContext.state.user){
              // console.log('appsContext.state.user: ', appsContext.state.user)
                        getAssetsByCategory("CelebrityInterviews")
                        getAssetsByCategory("Fashion")
                        getAssetsByCategory("RedCarpetEvents")
                        getAssetsByCategory("Nature")
                        getAssetsByCategory("CelebrityCandids")
                        getAssetsByCategory("News")
                        getAssetsByCategory("Travel")
                        getAssetsByCategory("Sports")
                        getAssetsByCategory("Music")
                        getAssetsByCategory("Arts")
                        getAssetsByCategory("Aerial")
                      // }
          }
            
              // console.log('appsContext.state.currentCategory:', appsContext.state.currentCategory)
              // console.log('appsContext.state.categoryFilter:', appsContext.state.categoryFilter)
              // console.log('appsContext.state.categoryMetaField:', appsContext.state.categoryMetaField)
              // if(typeof appsContext.state.categoryFilter == 'undefined'){
              //     appsContext.dispatch({ type: "NEWCATEGORY",  {currentCategory: 'RedCarpetEvents'}});
              // }
              // currentCategory = (appsContext.state.currentCategory)?appsContext.state.currentCategory
              // console.log('currentCategory:', currentCategory)
              // getCachedAssetData()
              // getAssetData()
              
             
              // console.log('fininshed loading videos.')
              // appsContext.dispatch({type:"LOADER", isLoading: 0})
              if( appsContext.state.isAdmin ){
                // getAssetSearchData()
              }
					    // getData();
					    
					    // const subscription = API.graphql(graphqlOperation(onCreateAlbum)).subscribe({
						   //    next: eventData => {
						   //      const album = eventData.value.data.onCreateAlbum;
						   //      dispatch({ type: "SUBSCRIPTION", album });
						   //    }
						   //  });
						    return () => {
                  // console.log('unmounting videos.')
						    	//console.log('state2: ', state)
						      // subscription.unsubscribe();
                  appsContext.dispatch({type:"CLEARALBUMS"})

						    };
          } catch(error) {
            // console.log('useEffect error:',error)
            isMounted = false
          }
					}, [ appsContext.state.currentPage, appsContext.state.user ]);


	async function getAssetSearchData() {
		
		// console.log('getting all assets...')
	    try{
	    	
      const albumData = await API.graphql(graphqlOperation(ListAllAssets,{coId:"6B3E1A00BDAB94E4DA166A30A759D44C",  searchStr:appsContext.state.categoryFilter, searchType:appsContext.state.categoryMetaField, start: appsContext.state.start, limit: appsContext.state.limit}));
			
		    // console.log('albumData:', albumData)
		    appsContext.dispatch({ type: "QUERY", albums: albumData.data.listAllAssetsFTSearch });

        albumData.data.listAllAssetsFTSearch.forEach( async (assetO) => {
          // addAssetDelta(assetO)
          updateAssetDelta(assetO)
        })
	    }catch(err){
	    	
	    	//add an image placehold for assets with no image
	    	// appsContext.dispatch({ type: "QUERY", albums: [{id:`none`,name:`none`, headline:`none`}] });
	    	// console.log('error: ', err)
	    }
	    

	}

  async function getCachedAssetData() {    
    // console.log('getting cached assets...')
      try{
        // console.log('currentCategory:', currentCategory)
      // const albumDataCached = await API.graphql(graphqlOperation(ListShotwireAssetsDeltas,{filter: {category:{contains: "red carpet"}}, limit: appsContext.state.cacheLimit, nextToken: appsContext.state.nextToken}));
        // console.log('appsContext.state.cacheLimit:', appsContext.state.cacheLimit)
        const albumDataCached = await API.graphql(graphqlOperation(ListShotAssetsDateDeltasIndex,{category:currentCategory,filter: {archived:{eq:1}}, limit: appsContext.state.cacheLimit, nextToken: appsContext.state.nextToken, scannedCount: appsContext.state.scannedCount}));

        // console.log('albumDataCached:', albumDataCached)
        appsContext.dispatch({ type: "QUERYCACHE", albumConnection: albumDataCached.data.listShotAssetsDateDeltasIndex });
        
      }catch(err){        
        //add an image placehold for assets with no image
        // appsContext.dispatch({ type: "QUERY", albums: [{id:`none`,name:`none`, headline:`none`}] });
        // console.log('error: ', err)
      }      
  }

  async function getAssetData() {    
    // console.log('getting assets...')
      try{
        // console.log('currentCategory:', currentCategory)
      // const albumDataCached = await API.graphql(graphqlOperation(ListShotwireAssetsDeltas,{filter: {category:{contains: "red carpet"}}, limit: appsContext.state.cacheLimit, nextToken: appsContext.state.nextToken}));
        // console.log('appsContext.state.cacheLimit:', appsContext.state.cacheLimit)
        const albumDataCached = await API.graphql(graphqlOperation(listShotwireAssetsDeltas,{filter: null, limit: appsContext.state.cacheLimit, nextToken: appsContext.state.nextToken, scannedCount: appsContext.state.scannedCount}));

        // console.log('albumDataCached:', albumDataCached)
        appsContext.dispatch({ type: "QUERYCACHE", albumConnection: albumDataCached.data.listShotwireAssetsDeltas });
        
      }catch(err){        
        //add an image placehold for assets with no image
        // appsContext.dispatch({ type: "QUERY", albums: [{id:`none`,name:`none`, headline:`none`}] });
        // console.log('error: ', err)
      }      
  }

  async function getAssetsByCategory(category) {    
    // console.log('getting assets...')
      try{
        // console.log('currentCategory:', currentCategory)
      // const albumDataCached = await API.graphql(graphqlOperation(ListShotwireAssetsDeltas,{filter: {category:{contains: "red carpet"}}, limit: appsContext.state.cacheLimit, nextToken: appsContext.state.nextToken}));
        // console.log('appsContext.state.cacheLimit:', appsContext.state.cacheLimit)
        // const albumDataCached = await API.graphql(graphqlOperation(listAssetsByCategory,{ category: category, limit: appsContext.state.cacheLimit, nextToken: appsContext.state.nextToken}));
        // console.log('appsContext.state.user->', appsContext.state.user)
        const albumDataCached = await API.graphql(
          {
              // query: listAssetsByCategory,
              query: listAssetsByCategoryPublic,
              variables: { category: category, filter:{marketplace:{ne:false}},limit: appsContext.state.publicHomeCategoryLimit, nextToken: appsContext.state.nextToken },
              authMode: ( (appsContext.state.isAdmin===true || appsContext.state.isUploader===true  || appsContext.state.user!==null )  ? 'AMAZON_COGNITO_USER_POOLS': 'AWS_IAM')
            }
          );
        

        // console.log('albumDataCached:', albumDataCached)
        appsContext.dispatch({ type: "CATEGORYCACHE", albumConnection: albumDataCached.data.listAssetsByCategory, category:category });
        // appsContext.dispatch({ type: "CATEGORYCACHE", albumConnection: albumDataCached.data.listAssetsByCategory, category:category });
        
        
        // console.log('albumsByCategory:', appsContext.state.albumsByCategory)
      }catch(err){        
        //add an image placehold for assets with no image
        // appsContext.dispatch({ type: "QUERY", albums: [{id:`none`,name:`none`, headline:`none`}] });
        // console.log('get assets err: ', err)
      }      
  }

  const createAssetDelta = `mutation createAssetDelta($input: CreateShotwireAssetsDeltaInput!) {
      createShotwireAssetsDelta(input: $input) {
        id
        assetId
        name

      }
    }
    `;

  async function addAssetDelta(assetObj) {
    try{
      //*old* decided to use only one category to tag an asset in order to utilize db index
      // // get current category and add this catgory if not already there.
      // // split the category by comma and create array then push item into array but not dupe
      // // console.log('asset cat:',assetObj.category)
      // const tmp = (assetObj.category != null) ? assetObj.category.split(',') : []
      // if(!tmp.includes('red carpet')) tmp.push('red carpet')
      // // console.log('tmp:', tmp)
      // const cat = tmp.join(',')
      // console.log('cat:', cat)
      const asset = {...assetObj, archived: "true", category: currentCategory}
       
      Object.keys(asset).forEach((key) => (asset[key] == null||asset[key] == '') && delete asset[key]);
      // console.log('asset->', asset)
      await API.graphql(graphqlOperation(createAssetDelta, { input: asset }));
    } catch(err) {
      // console.log('delta err:',err)
    }
    
  }

  const assetDeltaUpdate = `mutation updateAssetDelta($input: UpdateShotAssetsDeltaInput!) {
      updateShotAssetsDelta(input: $input) {
        id
        category
        assetId
        name
      }
    }
    `;

  async function updateAssetDelta(assetObj) {
    try{
      // console.log('date:', assetObj.setDate)
      const formattedDate = [assetObj.setDate.slice(0, 4), assetObj.setDate.slice(4,6), assetObj.setDate.slice(6,8)].join('-')
      // console.log('formattedDate:', formattedDate)
      const eventDate = new Date(formattedDate).getTime()
      // console.log('eventDate:',eventDate)
      //**NOTE** should updateAsset category only! 
      const asset = {id:assetObj.id, category: currentCategory, timestamp: eventDate }      
      Object.keys(asset).forEach((key) => (asset[key] == null||asset[key] == '') && delete asset[key]);
      // console.log('asset->', asset)
      await API.graphql(graphqlOperation(assetDeltaUpdate, { input: asset }));
    } catch(err) {
      // console.log('delta err:',err)
    }    
  }
   
  function AmazonAdBlock(props){
    // console.log('props:', props)
    if(props.adInfo.name === "musicAd-Beatles300x250"){ 
      // console.log('ad placed.')
      return(<iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=12&l=ur1&category=primemusic&banner=0P4KYM01CMVBKM9WDMR2&f=ifr&linkID=cb905615ed0e0ee429ed3ca5b208a12d&t=shotwire-20&tracking_id=shotwire-20" width="300" height="250" scrolling="no" border="0" marginWidth="0" style={{border:"none"}} frameBorder="0"></iframe>)
    }else{
      return('should be ad here.')
    }
  }

  return(
 

 <Container sx={{
  // bg: 'pink',//whiteBg,//
  color: bodyText,
  // width: '1440px'
 }}>

 {
  // <iframe 
  // src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=48&l=ur1&category=musicandentertainmentrot&f=ifr&linkID=77aa91ab641e94b96ee2bcb9aa9f50e9&t=shotwire-20&tracking_id=shotwire-20" 
  // width="728" 
  // height="90" 
  // scrolling="no" 
  // border="0" 
  // marginwidth="0" 
  // style="border:none;" 
  // frameborder="0"
  // />
}
 {   
 // <div sx={{
 //   width:'100%',
 //   padding: '25px'
 // }}
 // >
 }
 <Row>
 <Col sm={12} lg={12} className="d-md-none d-lg-none  d-xl-none" style={{textAlign:'center'}}>
                 
                  <iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=288&l=ur1&category=amazonhomepage&f=ifr&linkID=71d3093c671662bd39eab6a15e1dd5e3&t=shotwire-20&tracking_id=shotwire-20" width="320" height="50" scrolling="no" border="0" marginWidth="0" style={{border:"none"}} frameBorder="0"></iframe>  
                  </Col>
    <Col sm={12} lg={12} className="d-none d-md-block d-lg-block  d-xl-block">
    <div sx={{
  paddingTop:'15px',
  width:'100%',
  // height:'100px',
  textAlign:'center'
  // border:'1px solid white'
  // paddingLeft: '10px',
  // paddingTop: '20px'
 }}>
    <iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=48&l=ur1&category=musicandentertainmentrot&f=ifr&linkID=7915b0dca9ea2e04d98a3c550d386c0a&t=shotwire-20&tracking_id=shotwire-20" width="728" height="90" scrolling="no" border="0" marginWidth="0" style={{border:"none"}} frameBorder="0"></iframe>
 </div>
 </Col>
     </Row> 
{
 // <Row>
 // <div sx={{
 //  paddingTop:'15px',
 //  width:'1024px',
 //  height:'100px',
 //  textAlign:'center'
 //  // border:'1px solid white'
 //  // paddingLeft: '10px',
 //  // paddingTop: '20px'
 // }}>
 // <iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=48&l=ur1&category=musicandentertainmentrot&f=ifr&linkID=7915b0dca9ea2e04d98a3c550d386c0a&t=shotwire-20&tracking_id=shotwire-20" width="728" height="90" scrolling="no" border="0" marginWidth="0" style={{border:"none"}} frameBorder="0"></iframe>
 
 // </div>
 // </Row>
}
{
//  <Row>
//  <div sx={{
//   padding: '10px'
//  }}>
// <ButtonToolbar aria-label="Pagination Tool Bar">
//   <ButtonGroup size="sm" className="mr-2" aria-label="First group">
  
//   <Button variant="secondary" onClick={e => handlePrevPageClick(e, appsContext.state.activePage)}>
//     <FontAwesomeIcon
//           icon={faAngleLeft}
//           size="sm"
//           sx={{
//             // marginRight: '0.5rem'
//           }}
//         />
//      </Button>
//     {pages.map(page => (
//         <Button
//           key={page}
//           variant="secondary"
//           active={page === appsContext.state.activePage}
//           onClick={e => handlePageClick(e, page)}
//           // disabled={appsContext.state.currentPage <= 0}
//          >
//           {page}
//         </Button>
//         ))
//     }
//     <Button variant="secondary" onClick={e => handleNextPageClick(e, appsContext.state.activePage)}>
//     <FontAwesomeIcon
//           icon={faAngleRight}
//           size="sm"
//           sx={{
//             // marginRight: '0.5rem'
//           }}
//         />
//      </Button>     

//   </ButtonGroup>
// </ButtonToolbar>
// </div>
//  </Row>
}

 	<Row>
 	{ appsContext.state.isLoading ? (
          <div sx={{
  paddingLeft: '10px',
 }} >
          <Spinner animation="border" role="status" variant="warning">
            <span className="sr-only">Loading...</span>
          </Spinner>
     {   
      // <FontAwesomeIcon
      //       icon={faSpinner}
      //       size="sm"
      //       sx={{
      //         // marginRight: '0.5rem'
      //       }}
      //       spin
      //     />
        }
          </div>
        ) : (
          <div sx={{
  paddingLeft: '10px',
 }}></div>
        )
    }
 	</Row>
  
  {        
        (appsContext.state.albumsByCategory["CelebrityInterviews"].albums.length>0) ? <Row sx={{marginTop:'50px', fontWeight: 'bold',fontSize:'1.2rem', paddingLeft: '10px',}}>Interviews</Row>:""
    }

    {
      // console.log('interviews:', appsContext.state.albumsByCategory["CelebrityInterviews"].albums.splice(2, 0, {assetId:'AdBlock'}) )
      // (appsContext.state.albumsByCategory["CelebrityInterviews"].albums.length>0) ? appsContext.state.albumsByCategory["CelebrityInterviews"].albums.splice(4, 0, {assetId:'AdBlock'}):null
    }
  
    <Row>
        
        { 
// arr.splice(2, 0, "Lene");
          appsContext.state.albumsByCategory["CelebrityInterviews"].albums.map((asset, key) => (


                  
                    // (asset.assetId==='AdBlock') ?
                    
                    
                    //      <Box sx={{ width: ['100%', '50%', '33%'], px: 2, py: 2 }} key={key+'-AmazonAdBlock'}>
                    //                        <AmazonAdBlock adInfo={{name:'musicAd-Beatles300x250'}}></AmazonAdBlock>
                    //                        </Box>
                                           
                                      

                    // :
                    <Box sx={{ width: ['100%', '50%', '33%'], px: 2, py: 2 }} key={key}>
                  <VideoCard assetObj={asset} assetId={asset.assetId} name={ asset.suppCat } href="#!" image={
                    (asset.thumbNailUrl!=null)?asset.thumbNailUrl:require(`../assets/shotwire-logo.png`) 
                  }>
            [ { (asset.archived ? Math.trunc(asset.video_length/60) +':'+Math.round((asset.video_length/60).toString().substr((asset.video_length/60).toString().indexOf('.'),3)*60):'pending') } ]{'   '}{asset.city}<br />
            { (asset.headline) ? asset.headline.substr(0,100):(asset.archived ? 'missing headline':asset.name) } 
                  </VideoCard>
                  </Box>

                  

                

                // <Box sx={{ width: ['100%', '50%', '33%'], px: 2, py: 2 }} key={key}>
                //   {
            //         (key === 4) ?
                    
            //         <AmazonAdBlock adInfo={{name:'musicAd-Beatles300x250'}}></AmazonAdBlock>

            //         :
            //       <VideoCard assetObj={asset} assetId={asset.assetId} name={ asset.suppCat } href="#!" image={
            //         (asset.thumbNailUrl!=null)?asset.thumbNailUrl:require(`../assets/shotwire-logo.png`) 
            //       }>
            // [ { (asset.archived ? Math.trunc(asset.video_length/60) +':'+Math.round((asset.video_length/60).toString().substr((asset.video_length/60).toString().indexOf('.'),3)*60):'pending') } ]{'   '}{asset.city}<br />
            // { (asset.headline) ? asset.headline.substr(0,100):(asset.archived ? 'missing headline':asset.name) } 
            //       </VideoCard>

                //   }

                // </Box>


              )
          )
        }

        </Row>
        {
 <Row>
 <Col sm={12} lg={12} className="d-md-none d-lg-none  d-xl-none" style={{paddingTop:'15px',textAlign:'center'}}>
 {
 // <iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=12&l=ur1&category=musicandentertainmentrot&f=ifr&linkID=ba36135c872a9accec7e851088c8e190&t=shotwire-20&tracking_id=shotwire-20" width="300" height="250" scrolling="no" border="0" marginWidth="0" style={{border:"none"}} frameBorder="0"></iframe>
 }
<iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=12&l=ur1&category=amzn_music_bounty&banner=01ZTJ18Y6PDZG4QH4NG2&f=ifr&lc=pf4&linkID=5861fefab3c1ca5746e0c9c81e4c7fcb&t=shotwire-20&tracking_id=shotwire-20" width="300" height="250" scrolling="no" border="0" marginWidth="0" style={{border:"none"}} frameBorder="0"></iframe>

    </Col>
 <Col sm={12} lg={12} className="d-none d-md-block d-lg-block d-xl-block">
 <div sx={{
  paddingTop:'15px',
  width:'100%',
  height:'100px',
  textAlign:'center'
  // border:'1px solid white'
  // paddingLeft: '10px',
  // paddingTop: '20px'
 }}>
{ 
// <iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=48&l=ur1&category=primemusic&banner=1RY2Z0V0AAZV4RSZEXG2&f=ifr&linkID=58e39d79d4e3b1cbad1bc8f565011769&t=shotwire-20&tracking_id=shotwire-20" width="728" height="90" scrolling="no" border="0" marginWidth="0" style={{border:"none"}} frameBorder="0"></iframe>
}
<iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=48&l=ur1&category=amzn_music_bounty&banner=01HB06M5WV0M97AGH802&f=ifr&linkID=d5168639e4c802a6f74a18021297d93a&t=shotwire-20&tracking_id=shotwire-20" width="728" height="90" scrolling="no" border="0" marginWidth="0" style={{border:"none"}} frameBorder="0"></iframe>
</div>
</Col>
 </Row>
}
      {        
        (appsContext.state.albumsByCategory["Music"].albums.length>0) ? <Row sx={{marginTop:'50px', fontWeight: 'bold',fontSize:'1.2rem', paddingLeft: '10px',}}>Music</Row>:""
    }
    <Row >
        
        { 

          appsContext.state.albumsByCategory["Music"].albums.map((asset, key) => (

                <Box sx={{ width: ['100%', '50%', '33%'], px: 2, py: 2 }} key={key}>
                  <VideoCard assetObj={asset} assetId={asset.assetId} name={ asset.suppCat } href="#!" image={
                    (asset.thumbNailUrl!=null)?asset.thumbNailUrl:require(`../assets/shotwire-logo.png`) 
                  }>
            [ { (asset.archived ? Math.trunc(asset.video_length/60) +':'+Math.round((asset.video_length/60).toString().substr((asset.video_length/60).toString().indexOf('.'),3)*60):'pending') } ]{'   '}{asset.city}<br />
            { (asset.headline) ? asset.headline.substr(0,100):(asset.archived ? 'missing headline':asset.name) } 
                  </VideoCard>
                </Box>
              )
          )
        }

        </Row>
        

        {        
        (appsContext.state.albumsByCategory["Fashion"].albums.length>0) ? <Row sx={{marginTop:'50px', fontWeight: 'bold',fontSize:'1.2rem', paddingLeft: '10px',}}>Fashion</Row>:""
    }
    

    <Row>
        
        { 

          appsContext.state.albumsByCategory["Fashion"].albums.map((asset, key) => (

                <Box sx={{ width: ['100%', '50%', '33%'], px: 2, py: 2 }} key={key}>
                  <VideoCard assetObj={asset} assetId={asset.assetId} name={ asset.suppCat } href="#!" image={
                    (asset.thumbNailUrl!=null)?asset.thumbNailUrl:require(`../assets/shotwire-logo.png`) 
                  }>
            [ { (asset.archived ? Math.trunc(asset.video_length/60) +':'+Math.round((asset.video_length/60).toString().substr((asset.video_length/60).toString().indexOf('.'),3)*60):'pending') } ]{'   '}{asset.city}<br />
            { (asset.headline) ? asset.headline.substr(0,100):(asset.archived ? 'missing headline':asset.name) } 
                  </VideoCard>
                </Box>
              )
          )
        }

        </Row>

        {        
        (appsContext.state.albumsByCategory["Aerial"].albums.length>0) ? <Row sx={{marginTop:'50px', fontWeight: 'bold',fontSize:'1.2rem', paddingLeft: '10px',}}>Aerial Footage</Row>:""
    }
        <Row>
        
        { 

          appsContext.state.albumsByCategory["Aerial"].albums.map((asset, key) => (

                <Box sx={{ width: ['100%', '50%', '33%'], px: 2, py: 2 }} key={key}>
                  <VideoCard assetObj={asset} assetId={asset.assetId} name={ asset.suppCat } href="#!" image={
                    (asset.thumbNailUrl!=null)?asset.thumbNailUrl:require(`../assets/shotwire-logo.png`) 
                  }>
            [ { (asset.archived ? Math.trunc(asset.video_length/60) +':'+Math.round((asset.video_length/60).toString().substr((asset.video_length/60).toString().indexOf('.'),3)*60):'pending') } ]{'   '}{asset.city}<br />
            { (asset.headline) ? asset.headline.substr(0,100):(asset.archived ? 'missing headline':asset.name) } 
                  </VideoCard>
                </Box>
              )
          )
        }

        </Row>

        {        
        (appsContext.state.albumsByCategory["Travel"].albums.length>0) ? <Row sx={{marginTop:'50px', fontWeight: 'bold',fontSize:'1.2rem', paddingLeft: '10px',}}>Travel</Row>:""
    }
        <Row>
        
        { 

          appsContext.state.albumsByCategory["Travel"].albums.map((asset, key) => (

                <Box sx={{ width: ['100%', '50%', '33%'], px: 2, py: 2 }} key={key}>
                  <VideoCard assetObj={asset} assetId={asset.assetId} name={ asset.suppCat } href="#!" image={
                    (asset.thumbNailUrl!=null)?asset.thumbNailUrl:require(`../assets/shotwire-logo.png`) 
                  }>
            [ { (asset.archived ? Math.trunc(asset.video_length/60) +':'+Math.round((asset.video_length/60).toString().substr((asset.video_length/60).toString().indexOf('.'),3)*60):'pending') } ]{'   '}{asset.city}<br />
            { (asset.headline) ? asset.headline.substr(0,100):(asset.archived ? 'missing headline':asset.name) } 
                  </VideoCard>
                </Box>
              )
          )
        }

        </Row>
        
        {        
        (appsContext.state.albumsByCategory["Nature"].albums.length>0) ? <Row sx={{marginTop:'50px', fontWeight: 'bold',fontSize:'1.2rem', paddingLeft: '10px',}}>Nature</Row>:""
    }
        <Row>
        
        { 

          appsContext.state.albumsByCategory["Nature"].albums.map((asset, key) => (

                <Box sx={{ width: ['100%', '50%', '33%'], px: 2, py: 2 }} key={key}>
                  <VideoCard assetObj={asset} assetId={asset.assetId} name={ asset.suppCat } href="#!" image={
                    (asset.thumbNailUrl!=null)?asset.thumbNailUrl:require(`../assets/shotwire-logo.png`) 
                  }>
            [ { (asset.archived ? Math.trunc(asset.video_length/60) +':'+ Math.round((asset.video_length/60).toString().substr((asset.video_length/60).toString().indexOf('.'),3)*60):'pending') } ]{'   '}{asset.city}<br />
            { (asset.headline) ? asset.headline.substr(0,100):(asset.archived ? 'missing headline':asset.name) } 
                  </VideoCard>
                </Box>
              )
          )
        }

        </Row>
    
    {        
        (appsContext.state.albumsByCategory["News"].albums.length>0) ? <Row sx={{marginTop:'50px', fontWeight: 'bold',fontSize:'1.2rem', paddingLeft: '10px',}}>News</Row>:""
    }
        <Row>
        
        { 

          appsContext.state.albumsByCategory["News"].albums.map((asset, key) => (

                <Box sx={{ width: ['100%', '50%', '33%'], px: 2, py: 2 }} key={key}>
                  <VideoCard assetObj={asset} assetId={asset.assetId} name={ asset.suppCat } href="#!" image={
                    (asset.thumbNailUrl!=null)?asset.thumbNailUrl:require(`../assets/shotwire-logo.png`) 
                  }>
            [ { (asset.archived ? Math.trunc(asset.video_length/60) +':'+Math.round((asset.video_length/60).toString().substr((asset.video_length/60).toString().indexOf('.'),3)*60):'pending') } ]{'   '}{asset.city}<br />
            { (asset.headline) ? asset.headline.substr(0,100):(asset.archived ? 'missing headline':asset.name) } 
                  </VideoCard>
                </Box>
              )
          )
        }

        </Row>
      {
 <Row>
 <Col sm={12} lg={12} className="d-none d-md-block d-lg-block d-xl-block">
 <div sx={{
  paddingTop:'15px',
  width:'100%',
  height:'100px',
  textAlign:'center'
  // border:'1px solid white'
  // paddingLeft: '10px',
  
 }}>
 {
  // <iframe 
  // src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=48&l=ur1&category=amazonhomepage&f=ifr&linkID=8355df7f0e378d27c9867426a3cc6c5a&t=shotwire-20&tracking_id=shotwire-20" width="728" height="90" scrolling="no" border="0" marginwidth="0" 
  // style={{border:"none;"}} frameborder="0"></iframe>
 }
 <iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=48&l=ur1&category=beauty&banner=0Q2M96KTPZ1JX0G12SR2&f=ifr&linkID=e34788a49269de6c64a06c5519c7307b&t=shotwire-20&tracking_id=shotwire-20" width="728" height="90" scrolling="no" border="0" marginWidth="0" style={{border:"none"}} frameBorder="0"></iframe>
 
 </div>
 </Col>
 </Row>
}
        {        
        (appsContext.state.albumsByCategory["RedCarpetEvents"].albums.length>0) ? <Row sx={{marginTop:'50px', fontWeight: 'bold',fontSize:'1.2rem', paddingLeft: '10px',}}>Red Carpet</Row>:""
    }
        <Row>
        
        { 

          appsContext.state.albumsByCategory["RedCarpetEvents"].albums.map((asset, key) => (

                <Box sx={{ width: ['100%', '50%', '33%'], px: 2, py: 2 }} key={key}>
                  <VideoCard assetObj={asset} assetId={asset.assetId} name={ asset.suppCat } href="#!" image={
                    (asset.thumbNailUrl!=null)?asset.thumbNailUrl:require(`../assets/shotwire-logo.png`) 
                  }>
            [ { (asset.archived ? Math.trunc(asset.video_length/60) +':'+ Math.round((asset.video_length/60).toString().substr((asset.video_length/60).toString().indexOf('.'),3)*60):'pending') } ]{'   '}{asset.city}<br />
            { (asset.headline) ? asset.headline.substr(0,100):(asset.archived ? 'missing headline':asset.name) } 
                  </VideoCard>
                </Box>
              )
          )
        }

        </Row>
    <Row>   
    <Col sm={12} lg={12} className="d-none d-md-block d-lg-block d-xl-block">
       <div sx={{
  paddingTop:'15px',
  width:'100%',
  height:'100px',
  textAlign:'center'
  // border:'1px solid white'
  // paddingLeft: '10px',
  
 }}>
 <iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=48&l=ur1&category=primemain&banner=1FM3MKXZNJ0PPEBRV2R2&f=ifr&linkID=97f0c247004f7548309281f40eeb7940&t=shotwire-20&tracking_id=shotwire-20" width="728" height="90" scrolling="no" border="0" marginWidth="0" style={{border:"none"}} frameBorder="0"></iframe>
       
 </div>
 </Col> 
</Row>
       {        
        (appsContext.state.albumsByCategory["CelebrityCandids"].albums.length>0) ? <Row sx={{marginTop:'50px', fontWeight: 'bold',fontSize:'1.2rem', paddingLeft: '10px',}}>Celebrity Candids</Row>:""
    }
 
        <Row>
        
        { 

          appsContext.state.albumsByCategory["CelebrityCandids"].albums.map((asset, key) => (

                <Box sx={{ width: ['100%', '50%', '33%'], px: 2, py: 2 }} key={key}>
                  <VideoCard assetObj={asset} assetId={asset.assetId} name={ asset.suppCat } href="#!" image={
                    (asset.thumbNailUrl!=null)?asset.thumbNailUrl:require(`../assets/shotwire-logo.png`) 
                  }>
            [ { (asset.archived ? Math.trunc(asset.video_length/60) +':'+Math.round((asset.video_length/60).toString().substr((asset.video_length/60).toString().indexOf('.'),3)*60):'pending') } ]{'   '}{asset.city}<br />
            { (asset.headline) ? asset.headline.substr(0,100):(asset.archived ? 'missing headline':asset.name) } 
                  </VideoCard>
                </Box>
              )
          )
        }

        </Row>
 	
{
        // <Row>
        }      
        { 

          // appsContext.state.albumsCached.map((asset, key) => (

          //       <Box sx={{ width: ['100%', '50%', '33%'], px: 2, py: 2 }} key={key}>
          //         <VideoCard assetObj={asset} assetId={asset.assetId} name={ asset.suppCat } href="#!" image={
          //           (asset.thumbNailUrl!=null)?asset.thumbNailUrl:require(`../assets/shotwire-logo.png`) 
          //         }>
          //   [ { (asset.archived ? Math.trunc(asset.video_length/60) +':'+ Math.round((asset.video_length/60).toString().substr((asset.video_length/60).toString().indexOf('.'),3)*60):'pending') } ]{'   '}{asset.city}<br />
          //   { (asset.headline) ? asset.headline.substr(0,100):(asset.archived ? 'missing headline':asset.name) } 
          //         </VideoCard>
          //       </Box>
          //     )
          // )
        }
{
             // </Row>
             // <Row sx={{borderTop: '1px solid rgba(0,0,0, .5)'}} >
        }     
     { 
                  // !appsContext.state.albums.length ? <div sx={{ padding:'10px' }}>**</div> : <div sx={{ padding:'10px' }}>*</div>
                }
        {	

      //   	appsContext.state.albums.map((asset, key) => (

		    //         <Box sx={{ width: ['100%', '50%', '33%'], px: 2, py: 2 }} key={key}>
		    //           <VideoCard assetObj={asset} assetId={asset.assetId} name={ asset.suppCat } href="#!" image={`https://d1w2buicv2uw3m.cloudfront.net/V2/6B3E1A00BDAB94E4DA166A30A759D44C/assets/${asset.assetId}.jpg`}>
						// [ {Math.trunc(asset.video_length/60) +':'+ Math.round((asset.video_length/60).toString().substr((asset.video_length/60).toString().indexOf('.'),3)*60)} ]{'   '}{asset.city}<br />
						// { asset.headline.substr(0,100) } 
		    //           </VideoCard>
		    //         </Box>
		    //       )
      //   	)
        }
{
        // </Row>
}
        {
        //         <Row>
        //         <div sx={{
        //   padding: '10px'
        //  }}>
        // <ButtonToolbar aria-label="Pagination Tool Bar">
        //   <ButtonGroup size="sm" className="mr-2" aria-label="First group">
          
        //   <Button variant="secondary" onClick={e => handlePrevPageClick(e, appsContext.state.activePage)}>
        //     <FontAwesomeIcon
        //           icon={faAngleLeft}
        //           size="sm"
        //           sx={{
        //             // marginRight: '0.5rem'
        //           }}
        //         />
        //      </Button>
        //     {pages.map(page => (
        //         <Button
        //           key={page}
        //           variant="secondary"
        //           active={page === appsContext.state.activePage}
        //           onClick={e => handlePageClick(e, page)}
        //           // disabled={appsContext.state.currentPage <= 0}
        //          >
        //           {page}
        //         </Button>
        //         ))
        //     }
        //     <Button variant="secondary" onClick={e => handleNextPageClick(e, appsContext.state.activePage)}>
        //     <FontAwesomeIcon
        //           icon={faAngleRight}
        //           size="sm"
        //           sx={{
        //             // marginRight: '0.5rem'
        //           }}
        //         />
        //      </Button>     
        
        //   </ButtonGroup>
        // </ButtonToolbar>
        // </div>
        //         </Row>
              }

        </Container>
            
     
 )
}


// <Layout>
//     <SEO title="Celebrity Candids" />
//     <h1>Hi from the second page</h1>
//     <p>Celebrity Candids</p>
//     <Link to="/">Go back to the homepage</Link>
//   </Layout>

export default VideoArchiveView